import React, { useContext } from 'react';
import { Collapse, Navbar, Nav } from 'reactstrap';
import AppContext from '../../context/Context';
import Logo from './Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { topNavbarBreakpoint } from '../../config';

const NavbarTop = () => {
  const {
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);
  const handleBurgerMenu = () => {
    setNavbarCollapsed(!navbarCollapsed);
  };
  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit pt-4"
      expand={topNavbarBreakpoint}
    >
      <div
        className="toggle-icon-wrapper mr-md-3 mr-2 d-lg-none"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" width={200} id="topLogo" />
      <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
        <Nav navbar>
          <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
        </Nav>
      </Collapse>

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
