import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import envelope from '../../assets/img/envelope.png';

const ConfirmMail = () => (
  <div className="text-center">
    <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={92} />
    <h4>パスワードリセットメールを送信しました</h4>
    <br />
    <Button tag={Link} color="primary" className="mt-3" to="login">
      ログインに戻る
    </Button>
  </div>
);

export default ConfirmMail;
