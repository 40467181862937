import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Profile from '../components/profile/Profile';
import Clause from '../components/clause/Clause';
import Contact from '../components/contact/Contact';
import Dashboard from '../components/dashboard/Dashboard';

const DashboardRoutes = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/dashboard" exact component={Dashboard} />
    <Route path="/profile" exact component={Profile} />
    <Route path="/clause" exact component={Clause} />
    <Route path="/contact" exact component={Contact} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default DashboardRoutes;
