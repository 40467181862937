import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';

const ErrorRoutes = ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/404`} exact component={Error404} />
    <Route path={`${url}/500`} exact component={Error500} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default withRouter(ErrorRoutes);
