import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

const Layout = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={DashboardLayout} />
      </Switch>
    </Router>
  );
};

export default Layout;
