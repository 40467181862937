import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Error404 = () => (
  <div className="text-center">
    <div className="display-1 text-200 mb-4" style={{ height: 99, fontSize: "6rem"}}>404</div>
    <h4>ページが見つかりませんでした</h4>
    <br />
    <br />
    <Button tag={Link} color="primary" className="mt-3" to="/auth/login">
      ログインに戻る
    </Button>
  </div>
);

export default Error404;
