const insertStylesheet = (cb) => {
  const link = document.createElement('link');
  link.href = `${process.env.PUBLIC_URL}/css/theme.css`;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.className = 'theme-stylesheet';

  link.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(link);
  document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
};

export default (cb) => {
  Array.from(document.getElementsByClassName('theme-stylesheet')).forEach(link => link.remove());
  insertStylesheet(cb);
};
