import axios from 'axios';
import * as moment from "moment";
import { ja } from 'moment/locale/ja';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Datetime from 'react-datetime';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import { rgbaColor } from '../../helpers/utils';
import Loader from '../common/Loader';

const JepxLineChart = () => {
  const [area, setArea] = useState('chubu')
  const [unit, setUnit] = useState('week')
  const [loading, setLoading] = useState(true)
  const [ymd, setYmd] = useState(moment())
  const [prices, setPrices] = useState({ day: [], week: [], month: [] })
  const [labels, setLabels] = useState({
    day: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
    week: [],
    month: []
  })
  const [range, setRange] = useState([])

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/jepx/range').then(response => {
      console.log(response)
      setRange(response.data)
      setYmd(moment(response.data.max, "YYYY-MM-DD", true))
      setLoading(false)
    }).catch((error) => {
      console.log(error.response);
    });
  }, []);

  useEffect(() => {
    const label = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    setLabels({
      ...labels,
      week: label.map((hour) => ymd.clone().subtract(6, "days").format("YYYY-MM-DD") + ' ' + hour).concat(
        label.map((hour) => ymd.clone().subtract(5, "days").format("YYYY-MM-DD") + ' ' + hour),
        label.map((hour) => ymd.clone().subtract(4, "days").format("YYYY-MM-DD") + ' ' + hour),
        label.map((hour) => ymd.clone().subtract(3, "days").format("YYYY-MM-DD") + ' ' + hour),
        label.map((hour) => ymd.clone().subtract(2, "days").format("YYYY-MM-DD") + ' ' + hour),
        label.map((hour) => ymd.clone().subtract(1, "days").format("YYYY-MM-DD") + ' ' + hour),
        label.map((hour) => ymd.format("YYYY-MM-DD") + ' ' + hour),
      ),
      month: [...Array(31)].map((_, index) => {
        return ymd.clone().subtract(30 - index, "days").format("YYYY-MM-DD")
      })
    })
    console.log(labels)
  }, [ymd]);

  const changeDate = (changed) => {
    const mom = moment(changed, "YYYY-MM-DD", true)
    if (mom.isValid()) {
      setYmd(changed)
    }
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/jepx?area=${area}&ymd=${ymd.format("YYYY-MM-DD")}`).then(response => {
      console.log(response)
      setPrices(response.data)
    }).catch((error) => {
      console.log(error.response);
    });
  }, [area, ymd]);

  const isValidDate = (current) => {
    return moment(range.min, "YYYY-MM-DD", true).add(15, 'days') <= moment(current, "YYYY-MM-DD", true)
      && moment(current, "YYYY-MM-DD", true) <= moment(range.max, "YYYY-MM-DD", true)
  }

  const config = {
    data(canvas) {
      const ctx = canvas.getContext('2d')
      const gradientFill = ctx.createLinearGradient(0, 0, 0, 250)
      gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0.3)')
      gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)')

      return {
        labels: labels[unit],
        datasets: [
          {
            borderWidth: 2,
            data: prices[unit].map(x => x),
            borderColor: rgbaColor('#fff', 0.8),
            backgroundColor: gradientFill
          }
        ]
      }
    },
    options: {
      legend: { display: false },
      tooltips: {
        mode: 'x-axis',
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: tooltipItem => `${labels[unit][tooltipItem.index]} @${tooltipItem.yLabel}`,
          title: () => null
        }
      },
      hover: { mode: 'label' },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600,
              autoSkip: true,
              maxRotation: 9,
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600,
              beginAtZero: true,
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ]
      }
    }
  }

  const configSm = {
    options: {
      legend: { display: false },
      tooltips: {
        mode: 'x-axis',
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: tooltipItem => `${labels[unit][tooltipItem.index]} @${tooltipItem.yLabel}`,
          title: () => null
        }
      },
      hover: { mode: 'label' },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600,
              autoSkip: true,
              maxRotation: 0,
              maxTicksLimit: 1,
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600,
              beginAtZero: true,
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ]
      }
    }
  }

  return (
    <>
      {loading ? (
        <>
          <Card className="d-block d-md-none mb-1">
            <CardBody className="rounded-soft bg-gradient">
              <Row className="align-items-center no-gutters">
                <Col>
                  <h5 className="text-white mb-1">JEPX スポット価格（税抜）</h5>
                </Col>
              </Row>
              <Loader />
            </CardBody>
          </Card>
          <Card className="d-none d-md-block mt-3 mb-1">
            <CardBody className="rounded-soft bg-gradient">
              <Row className="align-items-center no-gutters">
                <Col>
                  <h5 className="text-white mb-1">JEPX スポット価格（税抜）</h5>
                </Col>
              </Row>
              <Loader />
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <div className="d-block d-md-none ">
            <CustomInput
              id="area"
              type="select"
              className="mt-3 mb-1 shadow"
              value={area}
              onChange={({ target }) => setArea(target.value)}
            >
              <option value="chubu">中部エリア</option>
              <option value="tokyo">東京エリア</option>
            </CustomInput>
            <Datetime
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              closeOnSelect={true}
              className="mb-1 shadow"
              locale={ja}
              value={ymd}
              onChange={changeDate}
              isValidDate={isValidDate}
            />
            <CustomInput
              id="ddd"
              type="select"
              className="mb-1 shadow"
              value={unit}
              onChange={({ target }) => setUnit(target.value)}
            >
              <option value="day">日</option>
              <option value="week">週</option>
              <option value="month">月</option>
            </CustomInput>
          </div>
          <Card className="d-block d-md-none mb-1">
            <CardBody className="rounded-soft bg-gradient">
              <Row className="align-items-center no-gutters">
                <Col>
                  <h5 className="text-white mb-1">JEPX スポット価格（税抜）</h5>
                </Col>
              </Row>
              <Line data={config.data} options={configSm.options} width={1618} height={2000} />
            </CardBody>
          </Card>
          <Card className="d-none d-md-block mt-3 mb-1">
            <CardBody className="rounded-soft bg-gradient">
              <Row className="align-items-center no-gutters">
                <Col>
                  <h5 className="text-white mb-1">JEPX スポット価格（税抜）</h5>
                </Col>
                <Col xs="auto" className="ml-2">
                  <CustomInput
                    id="area"
                    type="select"
                    className="mb-1 shadow"
                    value={area}
                    onChange={({ target }) => setArea(target.value)}
                  >
                    <option value="chubu">中部エリア</option>
                    <option value="tokyo">東京エリア</option>
                  </CustomInput>
                </Col>
                <Col xs="auto" className="ml-2">
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    closeOnSelect={true}
                    className="mb-1 shadow"
                    locale={ja}
                    value={ymd}
                    onChange={changeDate}
                    isValidDate={isValidDate}
                  />
                </Col>
                <Col xs="auto" className="ml-2">
                  <CustomInput
                    id="ddd"
                    type="select"
                    className="mb-1 shadow"
                    value={unit}
                    onChange={({ target }) => setUnit(target.value)}
                  >
                    <option value="day">日</option>
                    <option value="week">週</option>
                    <option value="month">月</option>
                  </CustomInput>
                </Col>
              </Row>
              <Line data={config.data} options={config.options} width={1618} height={305} />
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default JepxLineChart
