import classNames from 'classnames';
import React from 'react';
import logo from '../../assets/img/SYLA-POWER_logo_1.png';

const Logo = ({ at, width, className }) => {
  return (
    <div
      className={classNames(
        'd-flex',
        {
          'align-items-center py-3': at === 'navbar-vertical',
          'align-items-center': at === 'navbar-top',
          'flex-center font-weight-extra-bold fs-5 mb-3': at === 'auth'
        },
        className
      )}
    >
      <img className="mr-4" src={logo} alt="Logo" width={width} />
    </div>
  );
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
