import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ConfirmMail from '../components/auth/ConfirmMail';
import ForgetPassword from '../components/auth/ForgetPassword';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import PasswordReset from '../components/auth/PasswordReset';

const AuthBasicRoutes = () => (
  <Switch>
    <Route path={"/auth/login"} exact component={Login} />
    <Route path={"/auth/logout"} exact component={Logout} />
    <Route path={"/auth/forget-password"} exact component={ForgetPassword} />
    <Route path={"/auth/confirm-mail"} exact component={ConfirmMail} />
    <Route path={"/auth/password-reset"} exact component={PasswordReset} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default withRouter(AuthBasicRoutes);
