import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import rocket from '../../assets/img/rocket.png';
import AppContext from "../../context/Context";

const Logout = () => {
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/sanctum/csrf-cookie').then(response => {
      console.log(response);
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/logout').then((response) => {
        console.log(response);
      }).catch((response) => {
        console.log(response);
      })
    }).catch((response) => {
      console.log(response);
    })
  }, [])

  const { setAuth } = useContext(AppContext);
  setAuth(false);
  return (
    <div className="text-center">
      <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={99} />
      <h4>ログアウトしました</h4>
      <br />
      <br />
      <Button tag={Link} color="primary" className="mt-3" to="login">
        ログインに戻る
      </Button>
    </div>
  );
}

export default Logout;
