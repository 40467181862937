import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Button, Card, CardBody, Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import axios from 'axios';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const Contract = () => {
  const [type, setType] = useState('');
  const [reply, setReply] = useState('');
  const [body, setBody] = useState('');
  const [collapseOne, collapseOneOpen] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  useEffect(() => {
    setSubmitDisabled(!type || !reply || !body);
  }, [type, reply, body]);

  const handleSubmit = e => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/contact', {
      type: type.label,
      reply: reply,
      body: body
    }).then((response) => {
      console.log(response);
      collapseOneOpen(true);
      setType("");
      setReply("");
      setBody("");
    }).catch((response) => {
      console.log(response);
    });
  };
  return (
    <Card className="mt-3">
      <FalconCardHeader title="お問い合わせ" light={false} />
      <CardBody className="bg-light border-top">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="form-group mb-4">
            <Row>
              <Col lg={3} className="text-lg-right">
                <Label className="mt-1">
                  お問い合わせ種別
            </Label>
              </Col>
              <Col lg={7}>
                <Select
                  classNamePrefix='react-select'
                  options={[
                    { value: 1, label: '契約内容の変更に関するお問い合わせ' },
                    { value: 2, label: '住所変更に関するお問い合わせ' },
                    { value: 3, label: '解約に関するお問い合わせ' },
                    { value: 4, label: 'その他のお問い合わせ' },
                  ]}
                  placeholder="お問い合わせ種別を選択してください"
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  className="border-1"
                  value={type}
                  onChange={value => setType(value)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="form-group mb-4">
            <Row>
              <Col lg={3} className="text-lg-right">
                <Label className="mb-0">
                  返信希望
            </Label>
              </Col>
              <Col lg={7}>
                <CustomInput
                  type="radio"
                  name="exampleCustomInlineRadio"
                  id="exampleCustomInlineRadio"
                  value="電話"
                  label="電話"
                  inline
                  checked={reply=="電話"}
                  onChange={({ target }) => setReply(target.value)}
                  />
                <CustomInput
                  type="radio"
                  name="exampleCustomInlineRadio"
                  id="exampleCustomInlineRadio2"
                  value="メール"
                  label="メール"
                  inline
                  checked={reply=="メール"}
                  onChange={({ target }) => setReply(target.value)}
                  />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="form-group">
            <Row>
              <Col lg={3} className="text-lg-right">
                <Label className="mb-0" htmlFor="content">
                  お問い合わせ内容
            </Label>
              </Col>
              <Col lg={7}>
                <Input bsSize="sm" id="content" type="textarea" rows={20} className="pt-2"
                            value={body}
                            onChange={({ target }) => setBody(target.value)}
                 />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="form-group mb-1">
            <Row className="row">
              <Col lg={{ size: 7, offset: 3 }}>
                <Button color="primary" disabled={submitDisabled}>
                  送信
                </Button>
                <span className="ml-3">※ 別途、3営業日以内に担当からご返信させていただきます</span>
                <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
                  <ModalHeader>お問い合わせ送信完了通知</ModalHeader>
                  <ModalBody>
                    お問い合わせを受け付けいたしました<br />
                    3営業日以内に担当からご返信させていただきます
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={() => collapseOneOpen(!collapseOne)}>
                      閉じる
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Contract;
