import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import NavbarTop from '../components/navbar/NavbarTop';
import withRedirect from '../hoc/withRedirect';
import DashboardRoutes from '../routes/DashboardRoutes';

axios.defaults.withCredentials = true;
const DashboardLayout = ({ setRedirect, setRedirectUrl }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/user')
      .then((response) => {
        console.log(response);
        setLoaded(true);
      })
      .catch((response) => {
        console.log(response);
        setRedirectUrl("/auth/login");
        setRedirect(true);
      });
  });

  return (
    <div className="container">
      {loaded ? (
        <div className="content">
          <NavbarTop />
          <Switch>
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
      ) : (
        <div className="content">
        </div>
      )}
    </div>
  );
};

export default withRedirect(DashboardLayout);
