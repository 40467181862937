import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from "../../hoc/withRedirect";

const ForgetPassword = ({ setRedirect, setRedirectUrl, layout }) => {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState('');
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/forget', {
        email: email
      }).then((response) => {
        console.log(response);
        setRedirectUrl("confirm-mail");
        setRedirect(true);
      }).catch((response) => {
        console.log(response);
        setHasError(true);
      });
    }
  };

  return (
    <div>
      <Form className="mt-4" onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            className="form-control"
            placeholder="Eメールアドレス"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </FormGroup>
        <FormGroup>
          <Link block to="login">
            ログインに戻る
          </Link>
          <Button className="mt-3" color="primary" block disabled={!email}>
            パスワードリセットメールを送信
          </Button>
          {hasError && <span className="text-danger">ユーザーが存在しません。</span>}
          <br />
          {/* <Button tag={Link} className="fs--1" color="primary" className="mt-5" block to="login">
            ログインに戻る
          </Button> */}
        </FormGroup>
      </Form>
    </div>
  );
};

export default withRedirect(ForgetPassword);
