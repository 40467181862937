import React from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';


const App = () => {
  return (
    <Layout />
  );
};

export default App;
