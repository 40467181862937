import React from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import InvoicesTable from '../invoice/InvoicesTable';
import JepxLineChart from '../jepx/JepxLineChart';

const Dashboard = () => {
  return (
    <>
      <JepxLineChart />
      <Card className="mt-3">
        <FalconCardHeader title="電気料金一覧" light={false}>
        </FalconCardHeader>
        <CardBody className="p-0">
          <InvoicesTable />
        </CardBody>
      </Card>
    </>
  );
};

export default Dashboard;
