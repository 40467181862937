import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './helpers/utils';

const Main = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  const [isAuth, setAuth] = useState(getItemFromStore('isAuth', false));

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);
  const value = {
    toggleModal,
    isOpenSidePanel,
    navbarCollapsed,
    setIsOpenSidePanel,
    setNavbarCollapsed,
    isAuth,
    setAuth
  };

  useEffect(() => {
    setItemToStore('isAuth', isAuth);
    // eslint-disable-next-line
  }, [isAuth]);

  if (!isLoaded) {
    toggleStylesheet(() => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: themeColors.light
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
