import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import Label from 'reactstrap/es/Label';
import withRedirect from '../../hoc/withRedirect';

const PasswordReset = ({ setRedirect, setRedirectUrl, hasLabel }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/reset-password').then((response) => {
      console.log(response);
      setRedirectUrl("/auth//login");
      setRedirect(true);
    });
  };

  useEffect(() => {
    if (password === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(password !== confirmPassword);
  }, [password, confirmPassword]);

  return (
    <div className="text-center">
      <h5>Reset new password</h5>
      <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleSubmit}>
        <FormGroup>
          {hasLabel && <Label>New Password</Label>}
          <Input
            placeholder={!hasLabel ? 'New Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>Confirm Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Set password
      </Button>
      </Form>
    </div>
  );
};

export default withRedirect(PasswordReset);
